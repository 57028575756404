body {
  margin: 0;
  font-family: 'American Captain', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: 'American Captain', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none !important;

  overflow-y: hidden; /* Disables vertical scrolling */
  overflow-x: hidden; /* Enables horizontal scrolling */
  overscroll-behavior: none;
  scrollbar-width: none; /* Firefox */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  @media (orientation: portrait) {
    /*transform: rotate(90deg) translate(-50%, -50%); !* Rotate and center the content *!*/


  }


}
